import { Link } from "react-router-dom";
import { Breadcrumb, Card, Layout } from "antd";

import styles from "./styles.module.scss";
import Header from "./header";

const { Content, Footer } = Layout;

interface IMainLayout {
  children: React.ReactNode;
  breadcrumbs: { content: React.ReactNode; path: string }[];
}

const MainLayout: React.FC<IMainLayout> = ({ breadcrumbs, children }) => (
  <Layout className={styles.layout}>
    <Header />
    <Content className={styles.content}>
      <Breadcrumb className={styles.breadcrumb}>
        {breadcrumbs?.map(({ content, path }) => (
          <Breadcrumb.Item key={path}>
            <Link to={path}>{content}</Link>
          </Breadcrumb.Item>
        ))}
      </Breadcrumb>

      {children}
    </Content>
    <Footer className={styles.footer}>El Mooltaka ©2022</Footer>
  </Layout>
);

export default MainLayout;

export const MainContent: React.FC<React.ComponentProps<typeof Card>> = ({
  children,
  ...props
}) => (
  <Content className={styles.mainContent}>
    <Card bordered={false} {...props}>
      <div>{children}</div>
    </Card>
  </Content>
);

export const ContentWrapper: React.FC<{ children: React.ReactNode }> = ({
  children
}) => <Content className={styles.mainContent}>{children}</Content>;
