import { Empty, Table as AntdTable, TableProps } from "antd";

function Table(props: TableProps<any>) {
  return (
    <AntdTable
      showSorterTooltip={false}
      locale={{
        emptyText: (
          <Empty
            description="Pas de données"
            image={Empty.PRESENTED_IMAGE_SIMPLE}
          />
        )
      }}
      {...props}
    />
  );
}

export default Table;
