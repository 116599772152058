import { useAuth } from "providers/auth";
import { Navigate, useLocation } from "react-router-dom";

interface Props {
  children: React.ReactNode;
}

const RequireAuth: React.FC<Props> = ({ children }) => {
  const { accessToken } = useAuth();
  const location = useLocation();
  if (accessToken) return <>{children}</>;
  return (
    <Navigate
      to={{
        pathname: "/auth/sign-in"
      }}
      state={{ redirectTo: location.pathname + location.search }}
    />
  );
};

export default RequireAuth;
