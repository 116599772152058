export default function downloadFile(href: string) {
  const a = document.createElement("a") as HTMLAnchorElement;
  a.href = href;
  a.download = "true";
  document.body.appendChild(a);
  a.style.display = "none";
  a.setAttribute("target", "_blank");
  a.click();
  document.body.removeChild(a);
}
