import { Modal } from "antd";
import { DeleteOutlined } from "@ant-design/icons";
import { useDeleteCompany } from "./queries";

interface Props {
  isOpen: boolean;
  onClose: () => void;
  data?: string;
}

const DeleteCompany: React.FC<Props> = ({ isOpen, onClose, data }) => {
  const deleteCompany = useDeleteCompany({
    meta: { message: "L'archivage de la compagnie a bien été effectué" }
  });

  const onDelete = () => {
    deleteCompany.mutate(
      { company_id: data! },
      {
        onSuccess: onClose
      }
    );
  };
  return (
    <Modal
      confirmLoading={deleteCompany.isLoading}
      title={"Archiver la compagnie"}
      onCancel={onClose}
      visible={isOpen}
      onOk={onDelete}
      okType="danger"
      okText={
        <>
          <DeleteOutlined /> Archiver
        </>
      }
    >
      Êtes-vous sûr de vouloir Archiver cette compagnie?
    </Modal>
  );
};

export default DeleteCompany;
