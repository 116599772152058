import { useEffect, useState } from "react";

import { Button, DatePicker, Drawer, Form, Input, Upload } from "antd";
import type { UploadProps, UploadFile } from "antd";
import { InboxOutlined } from "@ant-design/icons";
import moment from "moment";

import styles from "./styles.module.scss";
import { ICreationDetails, INisForm, useEditNis } from "../queries";
import { FieldData } from "rc-field-form/es/interface";
import { useAuth } from "providers/auth";

interface Props {
  id: string;
  isOpen: boolean;
  onClose: () => void;
  data?: ICreationDetails["nis"];
}

const NisDrawer: React.FC<Props> = ({ isOpen, onClose, id, data }) => {
  const [nisForm] = Form.useForm();
  const { accessToken } = useAuth();
  const [nisFileList, setNisFileList] = useState<UploadFile[] | undefined>();

  useEffect(() => {
    setNisFileList(
      data
        ? [
            {
              uid: "2",
              status: "success",
              name: data?.nis.split("/").slice(-1)[0],
              url: `${data?.nis}?t=${btoa(accessToken!)}`
            }
          ]
        : undefined
    );
  }, [accessToken, data]);

  const { mutate, isLoading } = useEditNis(id, {
    meta: { message: "L'etape Nis a bien etait edité" },
    onSuccess() {
      closeDrawer();
    },
    onError(error: any) {
      let formErrors: FieldData[] = [];
      for (let key in error.response.data.errors) {
        formErrors.push({
          name: key,
          errors: [error.response.data.errors[key][0].message]
        });
      }
      nisForm.setFields(formErrors);
    }
  });
  console.log(data);

  const onFinish = (values: INisForm) => {
    const formData = new FormData();
    formData.append(
      "delivered_date",
      values.delivered_date.format("YYYY-MM-DD")
    );
    formData.append("nis_number", values.nis_number);
    formData.append("nis", values.nis?.file?.originFileObj || "");
    mutate(formData);
  };

  const closeDrawer = () => {
    onClose();
    nisForm.resetFields();
  };

  const handleNisFileChange: UploadProps["onChange"] = (info) => {
    setNisFileList([...info.fileList]);
  };

  return (
    <Drawer onClose={closeDrawer} visible={isOpen}>
      <Form
        form={nisForm}
        layout="vertical"
        onFinish={onFinish}
        className={styles.form}
        initialValues={
          data
            ? {
                delivered_date: data?.delivered_date
                  ? moment(data?.delivered_date)
                  : null,
                nis_number: data?.nis_number
              }
            : undefined
        }
      >
        <Form.Item
          rules={[
            { required: true, message: "Veuillez selectionner une date !" }
          ]}
          label="Date"
          name="delivered_date"
        >
          <DatePicker style={{ width: "100%" }} />
        </Form.Item>
        <Form.Item
          rules={[
            { required: true, message: "Veuillez inserer votre numero NIS !" }
          ]}
          label="No NIS"
          name="nis_number"
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Fichier NIS"
          name="nis"
          rules={
            !data?.nis
              ? [
                  {
                    required: true,
                    message: "Veuillez inserer votre fichier NIS !"
                  }
                ]
              : []
          }
        >
          <Upload.Dragger
            maxCount={1}
            customRequest={(options) =>
              setTimeout(() => options.onSuccess?.("ok"), 0)
            }
            onChange={handleNisFileChange}
            fileList={nisFileList}
          >
            <p className="ant-upload-drag-icon">
              <InboxOutlined />
            </p>
            <p className="ant-upload-hint">
              Cliquez ou faites glisser le fichier dans cette zone pour le
              télécharger
            </p>
          </Upload.Dragger>
        </Form.Item>
        <Form.Item>
          <Button loading={isLoading} type="primary" htmlType="submit">
            Sauvgarder
          </Button>
        </Form.Item>
      </Form>
    </Drawer>
  );
};

export default NisDrawer;
