import {
  useMutation,
  UseMutationOptions,
  useQuery,
  useQueryClient,
  UseQueryOptions
} from "react-query";
import api from "services/api";

export type IStatus = "init" | "created" | "completed";

interface IService {
  id: string;
  name: string;
  code: string;
  description: string;
}

export interface IApplication {
  appointment_count: number;
  created_at: string;
  id: string;
  last_appointment_date: string;
  name: string;
  services: IService[];
  status: IStatus;
}

export interface IApplicationDetail {
  id: string;
  cin: string;
  company_name: string;
  company_suggested_names: string[];
  company_type: string;
  created_at: string;
  denomination: string;
  email: string;
  first_name: string;
  known_denomination: string;
  last_name: string;
  name: string;
  nin: string;
  packs: { id: string; name: string }[];
  phone: string;
  place: { id: string; name: string };
  status: string;
}
interface IAppointment {
  comment: string;
  created_at: string;
  date: string;
  id: string;
}

export interface IAppointmentForm {
  date: string;
  comment: string;
}

export const useApplications = (config?: UseQueryOptions<IApplication[]>) => {
  return useQuery<IApplication[]>(["dashboard", "applications"], {
    ...config
  });
};

export const useDeleteApplication = (
  id: string,
  config?: UseMutationOptions
) => {
  const QueryClient = useQueryClient();
  return useMutation(() => api.delete(`dashboard/applications/${id}`), {
    ...config,
    onSuccess(data, vars, conext) {
      config?.onSuccess?.(data, vars, conext);
      QueryClient.invalidateQueries(["dashboard", "applications"]);
    }
  });
};

export const useApplication = (
  id: string,
  config?: UseQueryOptions<IApplicationDetail>
) => {
  return useQuery<IApplicationDetail>(["dashboard", "applications", id], {
    ...config
  });
};

export const useAppointments = (
  id: string,
  config?: UseQueryOptions<IAppointment[]>
) => {
  return useQuery<IAppointment[]>(
    ["dashboard", "applications", id, "appointments"],
    {
      ...config
    }
  );
};

export const useCreateAppointment = (
  id: string,
  config?: UseMutationOptions<unknown, unknown, IAppointmentForm, unknown>
) => {
  const queryClient = useQueryClient();
  return useMutation<unknown, unknown, IAppointmentForm, unknown>(
    (data) => api.post(`dashboard/applications/${id}/appointments`, data),
    {
      ...config,
      onSuccess(data, vars, conext) {
        config?.onSuccess?.(data, vars, conext);
        queryClient.invalidateQueries(["dashboard", "applications"]);
      }
    }
  );
};
