import {
  useMutation,
  UseMutationOptions,
  useQuery,
  useQueryClient,
  UseQueryOptions
} from "react-query";
import api from "services/api";
import { IPlaces } from "pages/formulaire/queries";

export interface IClientForm {
  company_type: string;
  name: string;
  email: string;
  phone: string;
  manager_first_name: string;
  manager_last_name: string;
  manager_phone: string;
  manager_email: string;
  domiciliation_place: {
    id: string;
    name: string;
    address: string;
  };
  domiciliation_date: string;
  application_id?: string;
}

export interface IClient {
  id: string;
  name: string;
  domiciliation_date: string;
  manager_email: string;
  manager_name: string;
  manager_phone: string;
}

export const useClients = (config?: UseQueryOptions<IClient[]>) => {
  return useQuery<IClient[]>(["dashboard", "clients"], {
    ...config
  });
};

export const useClient = (
  id: string,
  config?: UseQueryOptions<IClientForm>
) => {
  return useQuery<IClientForm>(["dashboard", "clients", id], {
    ...config
  });
};

export const useCreateClient = (
  config?: UseMutationOptions<unknown, unknown, IClientForm, unknown>
) => {
  const QueryClient = useQueryClient();
  return useMutation<unknown, unknown, IClientForm, unknown>(
    (data) => api.post("dashboard/clients", data),
    {
      ...config,
      onSuccess(data, vars, conext) {
        config?.onSuccess?.(data, vars, conext);
        QueryClient.invalidateQueries(["dashboard", "clients"]);
      }
    }
  );
};

export const useEditClient = (
  id: string,
  config?: UseMutationOptions<unknown, unknown, IClientForm, unknown>
) => {
  const QueryClient = useQueryClient();
  return useMutation<unknown, unknown, IClientForm, unknown>(
    (data) => api.put(`dashboard/clients/${id}`, data),
    {
      ...config,
      onSuccess(data, vars, conext) {
        config?.onSuccess?.(data, vars, conext);
        QueryClient.invalidateQueries(["dashboard", "clients"]);
      }
    }
  );
};

export const useDeleteClient = (id: string, config?: UseMutationOptions) => {
  const QueryClient = useQueryClient();

  return useMutation(() => api.delete(`dashboard/clients/${id}`), {
    ...config,
    onSuccess(data, vars, conext) {
      config?.onSuccess?.(data, vars, conext);
      QueryClient.invalidateQueries(["dashboard", "clients"]);
    }
  });
};

export const usePlaces = (config?: UseQueryOptions<IPlaces[]>) => {
  return useQuery<IPlaces[]>(["dashboard", "places"], {
    ...config
  });
};
