import { Row, Col, Space, Typography, Button } from "antd";
import {
  EyeOutlined,
  TagOutlined,
  DownloadOutlined,
  ArrowLeftOutlined
} from "@ant-design/icons";

import styles from "./styles.module.scss";

import MainLayout, { MainContent } from "modules/layout/dashboard";

import { useApplicationId } from "modules/router";
import { useApplication } from "./queries";
import { useAuth } from "providers/auth";
import downloadFile from "utils/download-file";
import Loader from "components/loader";
import { useNavigate } from "react-router-dom";

const { Title, Text } = Typography;

const ShowApplication: React.FC = () => {
  const applicationId = useApplicationId();
  const { data, isLoading } = useApplication(applicationId);
  const { accessToken } = useAuth();
  const downloadCin = () => {
    let url = `${data?.cin}?t=${btoa(accessToken!)}`;
    downloadFile(url);
  };

  const breadcrumbs = [
    {
      content: (
        <Space>
          <TagOutlined />
          Reservations
        </Space>
      ),
      path: "/reservations"
    },
    {
      content: (
        <Space>
          <EyeOutlined /> Reservation detail
        </Space>
      ),
      path: `/reservations/${applicationId}`
    }
  ];
  const navigate = useNavigate();

  return (
    <MainLayout breadcrumbs={breadcrumbs}>
      <MainContent>
        {isLoading ? (
          <div className="loaderContainer">
            <Loader />
          </div>
        ) : (
          <>
            <Col lg={{ span: 12, offset: 6 }} sm={{ span: 20, offset: 2 }}>
              <Row>
                <Col span={2}>
                  <Button
                    type="text"
                    shape="circle"
                    onClick={() => navigate("..")}
                    icon={<ArrowLeftOutlined />}
                  />
                </Col>
                <Col span={22}>
                  <Title level={3}>
                    Formulaire d'une réservation de domiciliation
                  </Title>
                  <Title level={4}>Information sur le requérant</Title>

                  <div className={styles.section}>
                    <Space
                      direction="vertical"
                      size="middle"
                      style={{ display: "flex" }}
                    >
                      <Row>
                        <Col span={12}>
                          <Label>Nom :</Label>
                        </Col>
                        <Text strong>{data?.name}</Text>
                      </Row>
                      <Row>
                        <Col span={12}>
                          <Label>Prenom :</Label>
                        </Col>
                        <Text strong>{data?.last_name}</Text>
                      </Row>
                      <Row>
                        <Col span={12}>
                          <Label>Email :</Label>
                        </Col>
                        <Text strong>{data?.email}</Text>
                      </Row>
                      <Row>
                        <Col span={12}>
                          <Label>Télephone :</Label>
                        </Col>
                        <Text strong>{data?.phone}</Text>
                      </Row>

                      <Row>
                        <Col span={12}>
                          <Label>Numero d'idetification national :</Label>
                        </Col>
                        <Text strong>{data?.nin}</Text>
                      </Row>
                      <Row>
                        <Col span={12}>
                          <Label>CI biométrique</Label> <br />
                        </Col>
                        <Button
                          onClick={downloadCin}
                          type="primary"
                          icon={<DownloadOutlined />}
                        >
                          Telecharger
                        </Button>
                      </Row>
                    </Space>
                  </div>
                  <Title level={4}>Information sur la company</Title>
                  <div className={styles.section}>
                    <Space
                      direction="vertical"
                      size="middle"
                      style={{ display: "flex" }}
                    >
                      <Row>
                        <Col span={12}>
                          <Label>Type de compagnie :</Label>
                        </Col>
                        <Text strong>{data?.company_type}</Text>
                      </Row>
                      <Row>
                        <Label>Dénomination :</Label>
                      </Row>
                      <div style={{ marginLeft: 10 }}>
                        {data?.denomination === "connue" ? (
                          <Row>
                            <Col span={12}>
                              <Label>Connue :</Label>
                            </Col>
                            <Text strong>{data.known_denomination}</Text>
                          </Row>
                        ) : (
                          <Row>
                            <Col span={12}>
                              <Label>Suggérée :</Label>
                            </Col>
                            <>
                              {data?.company_suggested_names &&
                                data?.company_suggested_names.map(
                                  (name, index) => (
                                    <Col key={index} span={24}>
                                      <Text strong>{name}</Text>
                                    </Col>
                                  )
                                )}
                            </>
                          </Row>
                        )}
                      </div>
                    </Space>
                  </div>
                  <Title level={4}>Information sur le service</Title>
                  <div className={styles.section}>
                    <Space
                      direction="vertical"
                      size="middle"
                      style={{ display: "flex" }}
                    >
                      <Row>
                        <Col span={12}>
                          <Label>Lieu de domiciliation :</Label>
                        </Col>
                        <Text strong>{data?.place && data?.place.name}</Text>
                      </Row>
                      <Row>
                        <Col span={24}>
                          <Label>Service :</Label>
                        </Col>
                        <div style={{ marginLeft: 10 }}>
                          {data?.packs &&
                            data?.packs.map((pack, index) => (
                              <Col key={index} span={24}>
                                <Text strong>{pack.name}</Text>
                              </Col>
                            ))}
                        </div>
                      </Row>
                    </Space>
                  </div>
                </Col>
              </Row>
            </Col>
          </>
        )}
      </MainContent>
    </MainLayout>
  );
};

export default ShowApplication;
interface LabelProps {
  children: React.ReactNode;
}

const Label: React.FC<LabelProps> = ({ children }) => (
  <Text strong style={{ color: "rgb(100 100 100)" }}>
    {children}
  </Text>
);
