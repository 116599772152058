import { Modal } from "antd";
import { DeleteOutlined } from "@ant-design/icons";
import { useDeleteApplication } from "./queries";

interface Props extends React.ComponentProps<typeof Modal> {
  onClose: () => void;
  id: string;
}

const DeleteApplication: React.FC<Props> = ({ onClose, id, ...props }) => {
  const { isLoading, mutate } = useDeleteApplication(id!, {
    meta: { message: "La suppression d'une reservation a bien etait effectué" }
  });
  const onDelete = () => {
    mutate(undefined, {
      onSettled() {
        onClose();
      }
    });
  };
  return (
    <Modal
      title={"Supprimer une reservation"}
      confirmLoading={isLoading}
      onCancel={() => {
        onClose();
      }}
      onOk={onDelete}
      okType="danger"
      okText={
        <>
          <DeleteOutlined /> Supprimer
        </>
      }
      {...props}
    >
      Cette reservation se supprimé définitivement
    </Modal>
  );
};

export default DeleteApplication;
