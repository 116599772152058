import { useCreationId } from "modules/router";
import MainLayout, { ContentWrapper } from "modules/layout/dashboard";
import {
  Descriptions,
  Collapse,
  Button,
  Space,
  Row,
  Col,
  Tag,
  Typography
} from "antd";
import Icon, {
  AuditOutlined,
  EyeOutlined,
  EditOutlined,
  CheckOutlined,
  DownloadOutlined,
  ArrowLeftOutlined
} from "@ant-design/icons";

import { usePopup } from "utils/use-popup";
import styles from "./styles.module.scss";
import CnrcDrawer from "./cnrc";
import ImpotsDrawer from "./impots";
import NisDrawer from "./nis";
import CasnosDrawer from "./casnos";
import { useNavigate } from "react-router-dom";
import {
  ICreationDetails,
  useCreationDetails,
  useFinishStep
} from "../queries";
import { formatDate } from "utils/date-format";
import { useEffect, useMemo, useState } from "react";
import Loader from "components/loader";
import { useAuth } from "providers/auth";
import downloadFile from "utils/download-file";

const CreationView: React.FC = () => {
  const creationId = useCreationId();
  const [activeKey, setActiveKey] = useState<ICreationDetails["status"]>();
  const cnrcDrawer = usePopup<ICreationDetails["cnrc"]>();
  const impotsDrawer = usePopup<ICreationDetails["impot"]>();
  const nisDrawer = usePopup<ICreationDetails["nis"]>();
  const casnosDrawer = usePopup<ICreationDetails["casnos"]>();

  const navigate = useNavigate();
  const { accessToken } = useAuth();
  const finishStep = useFinishStep(creationId!);
  const creationDetails = useCreationDetails(creationId!);

  useEffect(() => {
    setActiveKey(creationDetails.data?.status);
  }, [creationDetails.data?.status]);

  const breadcrumbs = useMemo(() => {
    return [
      {
        content: (
          <Space>
            <Icon component={() => <AuditOutlined />} />
            Creation
          </Space>
        ),
        path: "/creation"
      },
      {
        content: (
          <Space>
            <Icon component={() => <EyeOutlined />} />
            View
          </Space>
        ),
        path: `/creation/view/${creationId}`
      },
      {
        content: creationId,
        path: `/creation/view/${creationId}`
      }
    ];
  }, [creationId]);

  const sectionsStatus = useMemo(() => {
    return {
      cnrc:
        creationDetails.data?.status === "cnrc"
          ? "progress"
          : creationDetails.data?.cnrc
          ? "finished"
          : "pending",
      impot:
        creationDetails.data?.status === "impôts"
          ? "progress"
          : creationDetails.data?.impot
          ? "finished"
          : "pending",
      nis:
        creationDetails.data?.status === "nis"
          ? "progress"
          : creationDetails.data?.nis
          ? "finished"
          : "pending",
      casnos:
        creationDetails.data?.status === "casnos"
          ? "progress"
          : creationDetails.data?.casnos
          ? "finished"
          : "pending"
    };
  }, [creationDetails.data]);

  const downloadCnrc = () => {
    let url = `${creationDetails.data?.cnrc?.cnrc}?t=${btoa(accessToken!)}`;
    downloadFile(url);
  };

  const downloadExistence = () => {
    let url = `${creationDetails.data?.impot?.existence}?t=${btoa(
      accessToken!
    )}`;
    downloadFile(url);
  };

  const downloadNif = () => {
    let url = `${creationDetails.data?.impot?.nif}?t=${btoa(accessToken!)}`;
    downloadFile(url);
  };

  const downloadNis = () => {
    let url = `${creationDetails.data?.nis?.nis}?t=${btoa(accessToken!)}`;
    downloadFile(url);
  };

  const markDone = (status: string) => {
    finishStep.mutate({ status });
  };

  return (
    <MainLayout breadcrumbs={breadcrumbs}>
      <ContentWrapper>
        {creationDetails.isLoading ? (
          <div className="loaderContainer">
            <Loader />
          </div>
        ) : (
          <>
            <Space direction="vertical" style={{ width: "100%" }}>
              <Space>
                <Button
                  type="text"
                  shape="circle"
                  onClick={() => navigate("..")}
                  icon={<ArrowLeftOutlined />}
                ></Button>
                <Typography.Title style={{ marginBottom: 0 }} level={4}>
                  {creationDetails.data?.company_name}
                </Typography.Title>
              </Space>

              <Collapse
                activeKey={activeKey}
                onChange={() => setActiveKey("cnrc")}
                collapsible={
                  sectionsStatus.cnrc === "pending" ? "disabled" : undefined
                }
              >
                <Collapse.Panel
                  className={styles.collapseHeader}
                  key="cnrc"
                  header={
                    <Space>
                      CNRC
                      <Tag color={(statuses as any)[sectionsStatus.cnrc].color}>
                        {(statuses as any)[sectionsStatus.cnrc].label}
                      </Tag>
                    </Space>
                  }
                >
                  <Row>
                    <Col span={12}>
                      <Descriptions
                        column={1}
                        labelStyle={{
                          fontWeight: "bold",
                          alignItems: "center"
                        }}
                      >
                        <Descriptions.Item label="Date">
                          {creationDetails.data?.cnrc?.delivered_date
                            ? formatDate(
                                creationDetails.data?.cnrc?.delivered_date
                              )
                            : null}
                        </Descriptions.Item>
                        <Descriptions.Item label="Lieu">
                          {creationDetails.data?.cnrc?.delivered_place.address}
                        </Descriptions.Item>
                        <Descriptions.Item label="No registre">
                          {creationDetails.data?.cnrc?.cnrc_number}
                        </Descriptions.Item>
                        <Descriptions.Item label="Fichier registre">
                          <Button
                            type="primary"
                            onClick={downloadCnrc}
                            icon={<DownloadOutlined />}
                            disabled={!creationDetails.data?.cnrc?.cnrc}
                          >
                            Télécharger
                          </Button>
                        </Descriptions.Item>
                      </Descriptions>
                    </Col>
                    <Col span={12} className={styles.actionWrapper}>
                      <Space>
                        <Button
                          icon={<EditOutlined />}
                          onClick={() =>
                            cnrcDrawer.open(creationDetails.data?.cnrc)
                          }
                        >
                          Modifier
                        </Button>
                        <Button
                          type="primary"
                          icon={<CheckOutlined />}
                          loading={finishStep.isLoading}
                          onClick={() => markDone("cnrc")}
                          disabled={
                            !creationDetails.data?.cnrc ||
                            sectionsStatus.cnrc === "finished"
                          }
                        >
                          Terminer
                        </Button>
                      </Space>
                    </Col>
                  </Row>
                </Collapse.Panel>
              </Collapse>

              <Collapse
                activeKey={activeKey}
                onChange={() => setActiveKey("impôts")}
                collapsible={
                  sectionsStatus.impot === "pending" ? "disabled" : undefined
                }
              >
                <Collapse.Panel
                  className={styles.collapseHeader}
                  key="impôts"
                  header={
                    <Space>
                      IMPOTS
                      <Tag
                        color={(statuses as any)[sectionsStatus.impot].color}
                      >
                        {(statuses as any)[sectionsStatus.impot].label}
                      </Tag>
                    </Space>
                  }
                >
                  <Row>
                    <Col span={12}>
                      <Descriptions
                        column={1}
                        labelStyle={{
                          fontWeight: "bold",
                          alignItems: "center"
                        }}
                      >
                        <Descriptions.Item label="Date de debut d'activité">
                          {creationDetails.data?.impot?.start_date
                            ? formatDate(
                                creationDetails.data?.impot?.start_date
                              )
                            : null}
                        </Descriptions.Item>
                        <Descriptions.Item label="Ou délivré">
                          {creationDetails.data?.impot?.delivered_place.name}
                        </Descriptions.Item>
                        <Descriptions.Item label="No d'article">
                          {creationDetails.data?.impot?.article_number}
                        </Descriptions.Item>
                        <Descriptions.Item label="Fichier d'existance">
                          <Button
                            type="primary"
                            icon={<DownloadOutlined />}
                            onClick={downloadExistence}
                            disabled={!creationDetails.data?.impot?.existence}
                          >
                            Télécharger
                          </Button>
                        </Descriptions.Item>
                        <Descriptions.Item label="No NIF">
                          {creationDetails.data?.impot?.nif_number}
                        </Descriptions.Item>
                        <Descriptions.Item label="Fichier NIF">
                          <Button
                            type="primary"
                            onClick={downloadNif}
                            icon={<DownloadOutlined />}
                            disabled={!creationDetails.data?.impot?.nif}
                          >
                            Télécharger
                          </Button>
                        </Descriptions.Item>
                      </Descriptions>
                    </Col>
                    <Col span={12} className={styles.actionWrapper}>
                      <Space>
                        <Button
                          icon={<EditOutlined />}
                          onClick={() =>
                            impotsDrawer.open(creationDetails.data?.impot)
                          }
                        >
                          Modifier
                        </Button>
                        <Button
                          type="primary"
                          icon={<CheckOutlined />}
                          loading={finishStep.isLoading}
                          onClick={() => markDone("impôts")}
                          disabled={
                            !creationDetails.data?.impot ||
                            sectionsStatus.impot === "finished"
                          }
                        >
                          Terminer
                        </Button>
                      </Space>
                    </Col>
                  </Row>
                </Collapse.Panel>
              </Collapse>

              <Collapse
                activeKey={activeKey}
                onChange={() => setActiveKey("nis")}
                collapsible={
                  sectionsStatus.nis === "pending" ? "disabled" : undefined
                }
              >
                <Collapse.Panel
                  className={styles.collapseHeader}
                  key="nis"
                  header={
                    <Space>
                      NIS
                      <Tag color={(statuses as any)[sectionsStatus.nis].color}>
                        {(statuses as any)[sectionsStatus.nis].label}
                      </Tag>
                    </Space>
                  }
                >
                  <Row>
                    <Col span={12}>
                      <Descriptions
                        column={1}
                        labelStyle={{
                          fontWeight: "bold",
                          alignItems: "center"
                        }}
                      >
                        <Descriptions.Item label="Date">
                          {creationDetails.data?.nis?.delivered_date
                            ? formatDate(
                                creationDetails.data?.nis?.delivered_date
                              )
                            : null}
                        </Descriptions.Item>
                        <Descriptions.Item label="No NIS">
                          {creationDetails.data?.nis?.nis_number}
                        </Descriptions.Item>
                        <Descriptions.Item label="Fichier NIS">
                          <Button
                            type="primary"
                            onClick={downloadNis}
                            icon={<DownloadOutlined />}
                            disabled={!creationDetails.data?.nis?.nis}
                          >
                            Télécharger
                          </Button>
                        </Descriptions.Item>
                      </Descriptions>
                    </Col>
                    <Col span={12} className={styles.actionWrapper}>
                      <Space>
                        <Button
                          icon={<EditOutlined />}
                          onClick={() =>
                            nisDrawer.open(creationDetails.data?.nis)
                          }
                        >
                          Modifier
                        </Button>
                        <Button
                          type="primary"
                          icon={<CheckOutlined />}
                          loading={finishStep.isLoading}
                          onClick={() => markDone("nis")}
                          disabled={
                            !creationDetails.data?.nis ||
                            sectionsStatus.nis === "finished"
                          }
                        >
                          Terminer
                        </Button>
                      </Space>
                    </Col>
                  </Row>
                </Collapse.Panel>
              </Collapse>

              <Collapse
                activeKey={activeKey}
                onChange={() => setActiveKey("casnos")}
                collapsible={
                  sectionsStatus.casnos === "pending" ? "disabled" : undefined
                }
              >
                <Collapse.Panel
                  className={styles.collapseHeader}
                  key="casnos"
                  header={
                    <Space>
                      CASNOS
                      <Tag
                        color={(statuses as any)[sectionsStatus.casnos].color}
                      >
                        {(statuses as any)[sectionsStatus.casnos].label}
                      </Tag>
                    </Space>
                  }
                >
                  <Row>
                    <Col span={12}>
                      <Descriptions
                        column={1}
                        labelStyle={{
                          fontWeight: "bold",
                          alignItems: "center"
                        }}
                      >
                        <Descriptions.Item label="Date">
                          {creationDetails.data?.casnos?.delivered_date
                            ? formatDate(
                                creationDetails.data?.casnos?.delivered_date
                              )
                            : null}
                        </Descriptions.Item>
                        <Descriptions.Item label="No affiliation">
                          {creationDetails.data?.casnos?.casnos_number}
                        </Descriptions.Item>
                      </Descriptions>
                    </Col>
                    <Col span={12} className={styles.actionWrapper}>
                      <Space>
                        <Button
                          icon={<EditOutlined />}
                          onClick={() =>
                            casnosDrawer.open(creationDetails.data?.casnos)
                          }
                        >
                          Modifier
                        </Button>
                        <Button
                          type="primary"
                          icon={<CheckOutlined />}
                          loading={finishStep.isLoading}
                          onClick={() => markDone("casnos")}
                          disabled={
                            !creationDetails.data?.casnos ||
                            sectionsStatus.casnos === "finished"
                          }
                        >
                          Terminer
                        </Button>
                      </Space>
                    </Col>
                  </Row>
                </Collapse.Panel>
              </Collapse>
            </Space>

            <CnrcDrawer id={creationId!} {...cnrcDrawer} />
            <NisDrawer id={creationId!} {...nisDrawer} />
            <ImpotsDrawer id={creationId!} {...impotsDrawer} />
            <CasnosDrawer id={creationId!} {...casnosDrawer} />
          </>
        )}
      </ContentWrapper>
    </MainLayout>
  );
};

export default CreationView;

const statuses = {
  pending: {
    label: "En attente",
    color: "#ccc"
  },
  progress: {
    label: "En cours",
    color: "orange"
  },
  finished: {
    label: "Terminé",
    color: "green"
  }
};
