import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";

const RECAPTCHA_KEY = process.env.REACT_APP_RECAPTCHA_KEY;

interface IProps {
  children: React.ReactNode;
}

const RecaptchaProvider: React.FC<IProps> = ({ children }) => {
  return (
    <GoogleReCaptchaProvider
      useRecaptchaNet
      reCaptchaKey={RECAPTCHA_KEY}
      scriptProps={{ async: true, defer: true, appendTo: "body" }}
    >
      {children}
    </GoogleReCaptchaProvider>
  );
};

export default RecaptchaProvider;
