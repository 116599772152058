import { Button, Col, DatePicker, Form, Modal, Row, Space } from "antd";
import type { RangePickerProps } from "antd/es/date-picker";
import moment from "moment";
import TextArea from "antd/lib/input/TextArea";
import React from "react";
import {
  IAppointmentForm,
  useAppointments,
  useCreateAppointment
} from "./queries";
import styles from "./styles.module.scss";
import Table from "components/table";

interface Props extends React.ComponentProps<typeof Modal> {
  onClose: () => void;
  id: string;
}

const AppointmentModal: React.FC<Props> = ({ onClose, id, ...props }) => {
  const [appointmentForm] = Form.useForm<IAppointmentForm>();
  const appointments = useAppointments(id, {
    enabled: !!id
  });
  const { mutate, isLoading } = useCreateAppointment(id, {
    meta: { message: "Le rendez vous a bien etait ajouté" },
    onSuccess() {
      onClose();
      appointmentForm.resetFields();
    }
  });
  const handleSubmit = (values: any) => {
    mutate({ ...values, date: values.date.format("YYYY-MM-DD") });
  };

  const disabledDate: RangePickerProps["disabledDate"] = (current) => {
    return current && current < moment().endOf("day");
  };

  return (
    <Modal
      title={"Ajouter un rendez vous"}
      centered
      width={600}
      onCancel={() => {
        onClose();
        appointmentForm.resetFields();
      }}
      footer={[
        <Button
          loading={isLoading}
          form="rende_vous_form"
          key="submit"
          htmlType="submit"
        >
          Soumettre
        </Button>
      ]}
      {...props}
    >
      <Row>
        <Col span={24}>
          <Space style={{ display: "flex" }} direction="vertical" size={30}>
            <div className={styles.title}>Liste des anciens Rendez-vous</div>
            <Table
              dataSource={appointments.data}
              loading={appointments.isLoading || appointments.isFetching}
              pagination={false}
              rowKey="id"
              columns={[
                {
                  title: "Date",
                  dataIndex: "date",
                  key: "date"
                },
                {
                  title: "Commentaire",
                  dataIndex: "comment",
                  key: "comment"
                }
              ]}
            />
            <div className={styles.title}>Nouveau Rendez-vous</div>
            <Form
              form={appointmentForm}
              id="rende_vous_form"
              onFinish={handleSubmit}
            >
              <Form.Item
                name="date"
                rules={[{ required: true, message: "Veillez saisir une date" }]}
              >
                <DatePicker
                  disabledDate={disabledDate}
                  style={{ width: "100%" }}
                />
              </Form.Item>
              <Form.Item name="comment">
                <TextArea placeholder="Commentaire" />
              </Form.Item>
            </Form>
          </Space>
        </Col>
      </Row>
    </Modal>
  );
};

export default AppointmentModal;
