import {
  useMutation,
  UseMutationOptions,
  useQuery,
  useQueryClient,
  UseQueryOptions
} from "react-query";
import api from "services/api";
import { Moment } from "moment";
import { IClient } from "pages/clients/queries";

interface ICreation {
  id: string;
  name: string;
  manager_name: string;
  manager_phone: string;
  manager_email: string;
  domiciliation_date: string;
  status: string;
}

export interface IImpotsForm {
  start_date: Moment;
  delivered_place_id: string;
  article_number: string;
  existence: any;
  nif_number: string;
  nif: any;
}
interface IAdministrationPlaces {
  address: string;
  id: string;
  name: string;
  type: "cnrc" | "impôts" | "nis" | "casnos";
}

export interface ICnrcForm {
  delivered_date: Moment;
  delivered_place_id: string;
  cnrc_number: string;
  cnrc: any;
}

export interface INisForm {
  delivered_date: Moment;
  nis_number: string;
  nis: any;
}

export interface ICasnosForm {
  delivered_date: Moment;
  casnos_number: string;
}

export interface ICreateCompanyForm {
  company_id: string;
}

interface IDeleteCompany {
  company_id: string;
}

interface IFinishStep {
  status: string;
}

export interface ICreationDetails {
  status: "completed" | "cnrc" | "impôts" | "nis" | "casnos";
  company_name: string;
  cnrc?: {
    id: string;
    delivered_date: string;
    delivered_place: {
      id: string;
      name: string;
      address: string;
      type: string;
    };
    cnrc_number: string;
    cnrc: string;
  };
  impot?: {
    id: string;
    start_date: string;
    delivered_place: {
      id: string;
      name: string;
      address: string;
      type: string;
    };
    article_number: string;
    nif_number: string;
    existence: string;
    nif: string;
  };
  nis?: {
    id: string;
    delivered_date: string;
    nis_number: string;
    nis: string;
  };
  casnos?: {
    id: string;
    delivered_date: string;
    casnos_number: string;
  };
}

export const useCreationDetails = (
  id: string,
  config?: UseQueryOptions<ICreationDetails>
) => {
  return useQuery<ICreationDetails>(
    ["dashboard", "service", "creation", id, "show"],
    { ...config }
  );
};

export const useCreations = (config?: UseQueryOptions<ICreation[]>) => {
  return useQuery<ICreation[]>(
    ["dashboard", "service", "creation", "clients"],
    {
      ...config
    }
  );
};

export const useEditCnrc = (
  id: string,
  config?: UseMutationOptions<unknown, unknown, any>
) => {
  const QueryClient = useQueryClient();
  return useMutation<unknown, unknown, any>(
    (data) => api.post(`dashboard/service/creation/${id}/cnrc`, data),
    {
      ...config,
      onSuccess(data, vars, conext) {
        config?.onSuccess?.(data, vars, conext);
        QueryClient.invalidateQueries([
          "dashboard",
          "service",
          "creation",
          id,
          "show"
        ]);
      }
    }
  );
};

export const useEditImpots = (
  id: string,
  config?: UseMutationOptions<unknown, unknown, any>
) => {
  const QueryClient = useQueryClient();
  return useMutation<unknown, unknown, any>(
    (data) => api.post(`dashboard/service/creation/${id}/impot`, data),
    {
      ...config,
      onSuccess(data, vars, conext) {
        config?.onSuccess?.(data, vars, conext);
        QueryClient.invalidateQueries([
          "dashboard",
          "service",
          "creation",
          id,
          "show"
        ]);
      }
    }
  );
};

export const useAdministrationPlaces = (
  config?: UseQueryOptions<IAdministrationPlaces[]>
) => {
  return useQuery<IAdministrationPlaces[]>(["dashboard", "administrations"], {
    refetchOnWindowFocus: false,
    ...config
  });
};

export const useEditNis = (
  id: string,
  config?: UseMutationOptions<unknown, unknown, any>
) => {
  const QueryClient = useQueryClient();
  return useMutation<unknown, unknown, any>(
    (data) => api.post(`dashboard/service/creation/${id}/nis`, data),
    {
      ...config,
      onSuccess(data, vars, conext) {
        config?.onSuccess?.(data, vars, conext);
        QueryClient.invalidateQueries([
          "dashboard",
          "service",
          "creation",
          id,
          "show"
        ]);
      }
    }
  );
};

export const useEditCasnos = (
  id: string,
  config?: UseMutationOptions<unknown, unknown, any>
) => {
  const QueryClient = useQueryClient();
  return useMutation<unknown, unknown, any>(
    (data) => api.post(`dashboard/service/creation/${id}/casnos`, data),
    {
      ...config,
      onSuccess(data, vars, conext) {
        config?.onSuccess?.(data, vars, conext);
        QueryClient.invalidateQueries([
          "dashboard",
          "service",
          "creation",
          id,
          "show"
        ]);
      }
    }
  );
};

export const useCompanies = (config?: UseQueryOptions<IClient[]>) => {
  return useQuery<IClient[]>(
    ["dashboard", "service", "creation", "companies"],
    async () =>
      api
        .get("dashboard/clients", { params: { scope: "without_creation" } })
        .then(({ data }) => data?.data),
    { ...config }
  );
};

export const useCreateCompany = (
  config?: UseMutationOptions<unknown, unknown, ICreateCompanyForm>
) => {
  const QueryClient = useQueryClient();
  return useMutation(
    ({ company_id }) =>
      api.post(`dashboard/service/creation/${company_id}/assign`),
    {
      ...config,
      onSuccess(data, vars, conext) {
        config?.onSuccess?.(data, vars, conext);
        QueryClient.invalidateQueries([
          "dashboard",
          "service",
          "creation",
          "clients"
        ]);
      }
    }
  );
};

export const useDeleteCompany = (
  config?: UseMutationOptions<unknown, unknown, IDeleteCompany>
) => {
  const QueryClient = useQueryClient();
  return useMutation<unknown, unknown, IDeleteCompany>(
    ({ company_id }) =>
      api.post(`dashboard/service/creation/${company_id}/archive`),
    {
      ...config,
      onSuccess(data, vars, conext) {
        config?.onSuccess?.(data, vars, conext);
        QueryClient.invalidateQueries([
          "dashboard",
          "service",
          "creation",
          "clients"
        ]);
      }
    }
  );
};

export const useFinishStep = (
  id: string,
  config?: UseMutationOptions<unknown, unknown, IFinishStep>
) => {
  const QueryClient = useQueryClient();
  return useMutation<unknown, unknown, IFinishStep>(
    (data) => api.post(`dashboard/service/creation/${id}/complete`, data),
    {
      ...config,
      onSuccess(data, vars, conext) {
        config?.onSuccess?.(data, vars, conext);
        QueryClient.invalidateQueries([
          "dashboard",
          "service",
          "creation",
          id,
          "show"
        ]);
      }
    }
  );
};
