import AuthProvider from "./auth"
import QueryProvider from "./query"

interface IProps {
  children: React.ReactNode;
}

const AppProviders: React.FC<IProps> = ({ children }) => {
  return (
    <QueryProvider>
      <AuthProvider>{children}</AuthProvider>
    </QueryProvider>
  )
}

export default AppProviders
