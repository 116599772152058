import { Button, Form, Modal, Select, Space } from "antd";
import { ICreateCompanyForm, useCompanies, useCreateCompany } from "./queries";

interface Props{
  onClose: () => void;
  isOpen: boolean;
}

const AddCompanyModal: React.FC<Props> = ({ onClose, isOpen }) => {
  const [form] = Form.useForm();
  const companies = useCompanies();
  const company = useCreateCompany();

  const closeModal = () => {
    onClose();
    form.resetFields();
  };

  const onSubmit = (data: ICreateCompanyForm) => {
    company.mutate(data, {
      onSuccess: closeModal
    });
  };

  return (
    <Modal
      centered
      width={600}
      visible={isOpen}
      onCancel={closeModal}
      title={"Ajouter Compagnie"}
      footer={
        <Space>
          <Button onClick={closeModal}>Annuler</Button>
          <Button
            key="submit"
            type="primary"
            htmlType="submit"
            form="add_company_form"
            loading={company.isLoading}
          >
            Soumettre
          </Button>
        </Space>
      }
    >
      <Form
        form={form}
        layout="vertical"
        onFinish={onSubmit}
        id="add_company_form"
      >
        <Form.Item
          name="company_id"
          label="Sélectionner une compagnie"
          rules={[
            {
              required: true,
              message: "Veuillez selectioner une compagnie!"
            }
          ]}
        >
          <Select placeholder="Veuillez sélectionner une compagnie">
            {companies.data?.map((company) => (
              <Select.Option key={company.id} value={company.id}>
                {company.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default AddCompanyModal;
