import Axios from "axios";
import { dispatchLoggedOutEvent } from "providers/auth/utils";
import Qs from "qs";

const API_URL = process.env.REACT_APP_API_URL || "http://localhost:3000/api";

const api = Axios.create({
  baseURL: API_URL,
  withCredentials: true,
  headers: {
    "Content-Type": "application/json",
    "Accept-Language":
      localStorage.getItem("locale") || document.documentElement.lang || "en",
    Accept: "application/json"
  },
  paramsSerializer: function (params: any) {
    return Qs.stringify(params, { arrayFormat: "indices" });
  },
  responseType: "json"
});

api.interceptors.request.use(
  (config) => {
    const accessToken = localStorage.getItem("accessToken");
    if (accessToken) {
      // Add bearer header to request
      config.headers = {
        ...config.headers,
        Authorization: `Bearer ${accessToken}`
      };
    }
    return config;
  },
  (error) => Promise.reject(error)
);

api.interceptors.response.use(
  function (response) {
    if (response.config.method?.toLowerCase() !== "get") {
      return response.data;
    }
    return response;
  },
  function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error

    if (error.response.status === 401) {
      dispatchLoggedOutEvent();
      window.location.href = `/auth/sign-in`;
      return;
    }

    return Promise.reject(error);
  }
);
export default api;
