import { Suspense } from "react";
import ReactDOM from "react-dom/client";

import "./index.less";
import "./overrides.css";
import Router from "modules/router";
import AppProviders from "providers";
import Loading from "components/loader";
import { ErrorFallback } from "components/error";
import { BrowserRouter } from "react-router-dom";
import { ErrorBoundary } from "react-error-boundary";

const container = document.getElementById("root");
const root = ReactDOM.createRoot(container!);

root.render(
  <Suspense
    fallback={
      <div className="fullPageLoader">
        <Loading />
      </div>
    }
  >
    <BrowserRouter>
      <ErrorBoundary FallbackComponent={ErrorFallback}>
        <AppProviders>
          <Router />
        </AppProviders>
      </ErrorBoundary>
    </BrowserRouter>
  </Suspense>
);
