import { Space, Spin } from "antd";
import { FunctionComponent } from "react";

const Loader: FunctionComponent = (props) => {
  return (
    <Space size="middle">
      <Spin size="large" />
    </Space>
  );
};

export default Loader;
