import { MutationCache, QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import api from "services/api";
import { message as toast } from "antd";
import { dispatchLoggedOutEvent } from "./auth/utils";

interface IProps {
  children: React.ReactNode;
}

const queryClient = new QueryClient({
  mutationCache: new MutationCache({
    onSuccess(_, __, ___, mutation) {
      const meta = mutation.options.meta;
      let message: string = "";
      if (!!meta?.message) {
        message = meta?.message as string;
      }

      if (message) {
        toast.success(message);
      }
    },
    onError(error: any, __, ___, mutation) {
      const meta = mutation.options.meta;

      switch (true) {
        case meta?.handleError !== false && error.response.status !== 422:
          toast.error("Une erreur s'est produite");
          break;
        case error.response.status === 401:
          dispatchLoggedOutEvent();
          break;
      }
    }
  }),

  defaultOptions: {
    queries: {
      queryFn: async ({ signal, queryKey }) => {
        const params = queryKey
          .filter((key) => typeof key === "object")
          .reduce((acc: any, current: any) => ({ ...acc, ...current }), {});
        const res = await api.get(
          queryKey
            .filter(
              (key_1) => typeof key_1 === "string" || typeof key_1 === "number"
            )
            .join("/"),
          {
            params,
            signal
          }
        );
        return res.data?.data;
      },
      retry: (failures, error: any) => {
        if (error.response.status === 404) return false;
        return failures <= 3;
      },
      useErrorBoundary: (error: any) => {
        return error.response.status === 404;
      },
      notifyOnChangeProps: "tracked"
    }
  }
});

const QueryProvider: React.FC<IProps> = ({ children }) => {
  return (
    <QueryClientProvider client={queryClient}>
      <ReactQueryDevtools initialIsOpen={false} position="bottom-right" />
      {children}
    </QueryClientProvider>
  );
};

export default QueryProvider;
