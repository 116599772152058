import { Button, DatePicker, Drawer, Form, Input, Radio, Select } from "antd";
import Loader from "components/loader";
import moment, { Moment } from "moment";
import { IClientForm, useCreateClient, usePlaces } from "pages/clients/queries";
import { useEffect } from "react";
import { useApplication } from "./queries";
import styles from "./styles.module.scss";
import { FieldData } from "rc-field-form/es/interface";
import { useQueryClient } from "react-query";

interface Props {
  isOpen: boolean;
  onClose: () => void;
  id: string;
}

const CompleteApplication: React.FC<Props> = ({ isOpen, onClose, id }) => {
  const [completeForm] = Form.useForm();
  const applicationDetail = useApplication(id, {
    enabled: !!id
  });
  const { data: places } = usePlaces({
    enabled: isOpen
  });
  const queryClient = useQueryClient();
  const { mutate, isLoading } = useCreateClient({
    meta: { message: "Le client a bien etait ajouté" },
    onSuccess() {
      closeDrawer();
      queryClient.invalidateQueries(["dashboard", "applications"]);
    },
    onError(error: any) {
      let formErrors: FieldData[] = [];
      for (let key in error.response.data.errors) {
        formErrors.push({
          name: key,
          errors: [error.response.data.errors[key][0].message]
        });
      }
      completeForm.setFields(formErrors);
    }
  });

  const onFinish = (
    values: Omit<IClientForm, "domiciliation_date"> & {
      domiciliation_date: Moment;
    }
  ) => {
    mutate({
      ...values,
      domiciliation_date: values.domiciliation_date.format("YYYY-MM-DD"),
      application_id: id
    });
  };
  const closeDrawer = () => {
    onClose();
    completeForm.resetFields();
  };

  useEffect(() => {
    completeForm.resetFields();
  }, [applicationDetail.data, completeForm]);
  return (
    <Drawer
      visible={isOpen}
      onClose={closeDrawer}
      title={"Completer reservation"}
    >
      {applicationDetail.isLoading ? (
        <div className={styles.loaderContainer}>
          <Loader />
        </div>
      ) : (
        <Form
          layout="vertical"
          form={completeForm}
          onFinish={onFinish}
          className={styles.form}
          initialValues={{
            ...applicationDetail.data,
            domiciliation_place_id:
              applicationDetail.data?.place && applicationDetail.data?.place.id,
            manager_last_name: applicationDetail.data?.last_name,
            manager_first_name: applicationDetail.data?.first_name,
            manager_phone: applicationDetail.data?.phone,
            manager_email: applicationDetail.data?.email,
            name: applicationDetail.data?.company_name
          }}
        >
          <Form.Item
            name="company_type"
            label="Type de compagnie"
            rules={[
              {
                required: true,
                message: "Veuillez selectionner le type de compagnie!"
              }
            ]}
          >
            <Radio.Group>
              <Radio value="company">Morale</Radio>
              <Radio value="individual">Physique</Radio>
            </Radio.Group>
          </Form.Item>

          <Form.Item
            name="name"
            label="Nom"
            rules={[
              {
                required: true,
                message: "Veuillez saisir le nom de l'entreprise"
              }
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            name="domiciliation_place_id"
            label="Address"
            rules={[
              {
                required: true,
                message: "Veuillez selectioner une address de domiciliation!"
              }
            ]}
          >
            <Select placeholder="Veuillez sélectionner une adresse">
              {places?.map((place) => (
                <Select.Option key={place.id} value={place.id}>
                  {place.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item
            name="phone"
            label="Téléphone"
            rules={[
              {
                validator: (_, value) =>
                  value && !/^(00213|\+213|0)(5|6|7)[0-9]{8}$/.test(value)
                    ? Promise.reject("Le format du téléphone est invalide!")
                    : Promise.resolve()
              }
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            name="email"
            label="E-mail"
            rules={[
              {
                type: "email",
                message: "Veuillez saisir une adresse e-mail valide!"
              }
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item label="Site Web" name="website">
            <Input />
          </Form.Item>

          <Form.Item
            name="manager_last_name"
            label="Nom Responsable"
            rules={[
              {
                required: true,
                message: "Veuillez saisir le nom du responsable!"
              }
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            name="manager_first_name"
            label="Prénom Responsable"
            rules={[
              {
                required: true,
                message: "Veuillez saisir le prénom du responsable!"
              }
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Téléphone Responsable"
            name="manager_phone"
            rules={[
              {
                required: true,
                message:
                  "Veuillez saisir le numéro de téléphone d'un responsable!"
              },
              {
                validator: (_, value) =>
                  !/^(00213|\+213|0)(5|6|7)[0-9]{8}$/.test(value)
                    ? Promise.reject("Le format du téléphone est invalide!")
                    : Promise.resolve()
              }
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            name="manager_email"
            label="E-mail Responsable"
            rules={[
              {
                required: true,
                message: "Veuillez saisir l'adresse e-mail du responsable!"
              },
              {
                type: "email",
                message: "Veuillez saisir une adresse e-mail valide!"
              }
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Date de domiciliation"
            name="domiciliation_date"
            initialValue={moment()}
            rules={[
              {
                required: true,
                message: "Veuillez saisir une date de domiciliation"
              }
            ]}
          >
            <DatePicker style={{ width: "100%" }} />
          </Form.Item>

          <Form.Item>
            <Button loading={isLoading} type="primary" htmlType="submit">
              Sauvgarder
            </Button>
          </Form.Item>
        </Form>
      )}
    </Drawer>
  );
};

export default CompleteApplication;
