import { lazy } from "react";
import { Routes, Route, useParams } from "react-router-dom";

import NoMatch from "components/no-match";
import RequireAuth from "./require-auth";
import RequireNoAuth from "./require-no-auth";
import Applications from "pages/applications";
import RecaptchaProvider from "providers/recaptcha";
import ShowApplication from "pages/applications/show";
import Creation from "pages/creation";
import CreationView from "pages/creation/view";

const Auth = lazy(() => import("../../pages/auth"));
const Clients = lazy(() => import("../../pages/clients"));
const Formulaire = lazy(() => import("../../pages/formulaire"));

const Router: React.FC = () => {
  return (
    <Routes>
      <Route
        index
        element={
          <RequireAuth>
            <Clients />
          </RequireAuth>
        }
      />
      <Route path="/reservations">
        <Route
          index
          element={
            <RequireAuth>
              <Applications />
            </RequireAuth>
          }
        />
        <Route
          path=":applicationId"
          element={
            <RequireAuth>
              <ShowApplication />
            </RequireAuth>
          }
        />
      </Route>
      <Route path="/creation">
        <Route
          index
          element={
            <RequireAuth>
              <Creation />
            </RequireAuth>
          }
        />
        <Route
          path="view/:creationId"
          element={
            <RequireAuth>
              <CreationView />
            </RequireAuth>
          }
        />
      </Route>
      <Route
        path="/auth/*"
        element={
          <RequireNoAuth>
            <Auth />
          </RequireNoAuth>
        }
      />
      <Route
        path="/formulaire"
        element={
          <RecaptchaProvider>
            <Formulaire />
          </RecaptchaProvider>
        }
      />
      <Route path="*" element={<NoMatch />} />
    </Routes>
  );
};

function useApplicationId() {
  return useParams<"applicationId">().applicationId!;
}

function useCreationId() {
  return useParams<"creationId">().creationId;
}

export { useApplicationId, useCreationId };

export default Router;
