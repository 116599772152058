import { Button, DatePicker, Drawer, Form, Input } from "antd";
import styles from "./styles.module.scss";
import { ICasnosForm, ICreationDetails, useEditCasnos } from "../queries";
import { FieldData } from "rc-field-form/es/interface";
import moment from "moment";

interface Props {
  id: string;
  isOpen: boolean;
  onClose: () => void;
  data?: ICreationDetails["casnos"];
}

const CasnosDrawer: React.FC<Props> = ({ isOpen, onClose, id, data }) => {
  const [casnosForm] = Form.useForm();
  const { mutate, isLoading } = useEditCasnos(id, {
    meta: { message: "L'etape Casnos a bien etait edité" },
    onSuccess() {
      closeDrawer();
    },
    onError(error: any) {
      let formErrors: FieldData[] = [];
      for (let key in error.response.data.errors) {
        formErrors.push({
          name: key,
          errors: [error.response.data.errors[key][0].message]
        });
      }
      casnosForm.setFields(formErrors);
    }
  });
  const onFinish = (values: ICasnosForm) => {
    const formData = new FormData();
    formData.append(
      "delivered_date",
      values.delivered_date.format("YYYY-MM-DD")
    );
    formData.append("casnos_number", values.casnos_number);
    mutate(formData);
  };
  const closeDrawer = () => {
    onClose();
    casnosForm.resetFields();
  };
  return (
    <Drawer visible={isOpen} onClose={closeDrawer}>
      <Form
        form={casnosForm}
        layout="vertical"
        onFinish={onFinish}
        className={styles.form}
        initialValues={
          data
            ? {
                delivered_date: data?.delivered_date
                  ? moment(data?.delivered_date)
                  : null,
                casnos_number: data?.casnos_number
              }
            : undefined
        }
      >
        <Form.Item
          rules={[
            { required: true, message: "Veuillez selectionner une date !" }
          ]}
          label="Date"
          name="delivered_date"
        >
          <DatePicker style={{ width: "100%" }} />
        </Form.Item>
        <Form.Item
          name="casnos_number"
          rules={[
            {
              required: true,
              message: "Veuillez saisir un numero d'affiliation !"
            }
          ]}
          label="No affiliation"
        >
          <Input />
        </Form.Item>
        <Form.Item>
          <Button loading={isLoading} type="primary" htmlType="submit">
            Sauvgarder
          </Button>
        </Form.Item>
      </Form>
    </Drawer>
  );
};

export default CasnosDrawer;
