import { useContext } from "react";
import { MutationOptions, useMutation, useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";
import api from "services/api";
import { AuthContext } from ".";
import { LoginData, LoginResponse } from "./types";
import { dispatchLoggedInEvent, dispatchLoggedOutEvent } from "./utils";

export function useLogin(
  config?: MutationOptions<LoginResponse, unknown, LoginData>
) {
  const { setAccessToken } = useContext(AuthContext);
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  return useMutation<LoginResponse, unknown, LoginData>(
    (data: LoginData) => api.post("dashboard/account/auth", data),
    {
      onSuccess: (data) => {
        dispatchLoggedInEvent(data.bearer);
        setAccessToken(data.bearer);
        queryClient.resetQueries();
        navigate("/");
      },
      onError: () => {},
      ...config
    }
  );
}

export function useLogout(config?: MutationOptions<unknown, unknown, unknown>) {
  const navigate = useNavigate();
  const { setAccessToken } = useContext(AuthContext);

  return useMutation(() => api.post("dashboard/account/logout"), {
    onSuccess: (data) => {
      dispatchLoggedOutEvent();
      setAccessToken(null);
      navigate("/auth/sign-in");
    }
  });
}

export const useAccessToken = () => {
  let accessToken = localStorage.getItem("accessToken");
  return accessToken;
};
