import { Button, Dropdown, Menu, Space, Tag } from "antd";
import MainLayout, { MainContent } from "modules/layout/dashboard";
import Icon, {
  DeleteOutlined,
  EllipsisOutlined,
  FormOutlined,
  CheckOutlined,
  TagOutlined,
  CheckCircleOutlined,
  ExclamationCircleOutlined
} from "@ant-design/icons";
import { ReactComponent as AppointmentIcon } from "assets/icons/rendez_vous.svg";
import AppointmentModal from "./appointment";
import { usePopup } from "utils/use-popup";
import DeleteApplication from "./delete";
import CompleteApplication from "./complete";
import { useNavigate } from "react-router-dom";
import { IApplication } from "./queries";
import { IStatus, useApplications } from "./queries";
import { formatDate } from "utils/date-format";
import Table from "components/table";

const Applications: React.FC = () => {
  const applications = useApplications();

  const appointmentModal = usePopup<string>();
  const deleteModal = usePopup<string>();
  const completeDrawer = usePopup<string>();

  const navigate = useNavigate();

  return (
    <MainLayout breadcrumbs={breadcrumbs}>
      <MainContent extra={<div />}>
        <Table
          sticky={{ offsetScroll: 0, offsetHeader: 0 }}
          dataSource={applications.data}
          loading={applications.isLoading}
          rowKey="id"
          columns={[
            {
              title: "Date début",
              dataIndex: "created_at",
              key: "created_at",
              render: (cell) => formatDate(cell),
              sorter: (a, b) => a.created_at?.localeCompare(b.created_at)
            },
            {
              title: "Nom requérant",
              dataIndex: "name",
              key: "name",
              sorter: (a, b) => a.name?.localeCompare(b.name)
            },
            {
              title: "Date rendez-vous",
              dataIndex: "last_appointment_date",
              key: "last_appointment_date",
              sorter: (a, b) =>
                a.last_appointment_date?.localeCompare(b.last_appointment_date)
            },
            {
              title: "Création",
              key: "has_creation",
              render: (record) =>
                record.services?.find(
                  (service: any) => service.code === "creation"
                )
                  ? "Oui"
                  : "None",
              sorter: (a, b) => a.services?.length - b.services?.length
            },
            {
              title: "Statut",
              dataIndex: "status",
              key: "status",
              render: (cell: IStatus) => {
                const status = statuses[cell];
                return (
                  <Tag icon={status?.icon} color={status?.color}>
                    {status?.label}
                  </Tag>
                );
              },
              sorter: (a, b) => a.status?.localeCompare(b.status)
            },
            {
              title: "No Rendez-vous",
              dataIndex: "appointment_count",
              key: "appointment_count",
              sorter: (a, b) => a.appointment_count - b.appointment_count
            },
            {
              key: "action",
              title: "Action",
              width: 100,
              render: (record: IApplication) => (
                <Dropdown
                  trigger={["click"]}
                  overlay={
                    <Menu
                      style={{ width: "180px" }}
                      onClick={(info) => {}}
                      items={[
                        {
                          key: "complete",
                          icon: <CheckOutlined />,
                          label: "Complete",
                          disabled: record.status === "completed",
                          onClick: () => completeDrawer.open(record.id)
                        },
                        {
                          key: "formulaire",
                          icon: <FormOutlined />,
                          label: "Formulaire",
                          onClick: () => navigate(record.id)
                        },
                        {
                          key: "rendez_vous",
                          icon: (
                            <Icon
                              component={() => (
                                <AppointmentIcon
                                  width={14}
                                  fill="currentColor"
                                />
                              )}
                            />
                          ),
                          label: "Rendez vous",
                          onClick: () => appointmentModal.open(record.id)
                        },
                        {
                          key: "delete",
                          icon: <DeleteOutlined />,
                          label: "Supprimer",
                          onClick: () => deleteModal.open(record.id)
                        }
                      ]}
                    />
                  }
                >
                  <Button shape="circle" icon={<EllipsisOutlined />} />
                </Dropdown>
              )
            }
          ]}
        />
        <AppointmentModal
          visible={appointmentModal.isOpen}
          id={appointmentModal.data!}
          {...appointmentModal}
        />
        <DeleteApplication
          visible={deleteModal.isOpen}
          id={deleteModal.data!}
          {...deleteModal}
        />
        <CompleteApplication id={completeDrawer.data!} {...completeDrawer} />
      </MainContent>
    </MainLayout>
  );
};

export default Applications;

const breadcrumbs = [
  {
    content: (
      <Space>
        <TagOutlined />
        Reservations
      </Space>
    ),
    path: "/reservations"
  }
];

const statuses = {
  init: {
    color: "gold",
    label: "Initialisé",
    icon: <ExclamationCircleOutlined />
  },
  created: {
    color: "blue",
    label: "Créé",
    icon: <TagOutlined />
  },
  completed: {
    color: "success",
    label: "Terminé",
    icon: <CheckCircleOutlined />
  }
};
