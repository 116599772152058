import { useAuth } from "providers/auth";
import { Navigate, useLocation, useSearchParams } from "react-router-dom";

interface Props {
  children: React.ReactNode;
}

const RequireNoAuth: React.FC<Props> = ({ children }) => {
  const { accessToken } = useAuth();
  const location = useLocation();
  const [params] = useSearchParams();

  if (!accessToken) return <>{children}</>;

  return (
    <Navigate
      to={
        (location.state as any)?.redirectTo || params.get("redirectTo") || "/"
      }
    />
  );
};

export default RequireNoAuth;
