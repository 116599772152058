import { useMemo, useState } from "react";
import {
  Button,
  Card,
  Drawer,
  Layout,
  Menu,
  Space,
  Col,
  Row,
  Avatar
} from "antd";
import {
  PoweroffOutlined,
  TagOutlined,
  TeamOutlined,
  UserOutlined,
  AuditOutlined
} from "@ant-design/icons";

import { Link, matchPath, useLocation, useNavigate } from "react-router-dom";

import styles from "./styles.module.scss";
import Logo from "assets/logo.png";

import { useLogout } from "providers/auth/hooks";

const Header: React.FC<React.ComponentProps<typeof Layout.Header>> = () => {
  const [isDrawerVisible, setIsDrawerVisible] = useState<boolean>(false);
  const { mutate: logout, isLoading } = useLogout();
  const onOpenDrawer = () => setIsDrawerVisible(true);
  const onCloseDrawer = () => setIsDrawerVisible(false);
  const onLogout = () => logout();
  const location = useLocation();
  const navigate = useNavigate();
  const selectedKeys = useMemo(() => {
    return ["/", "/reservations", "/creation"].filter((path) =>
      matchPath(location.pathname, path)
    );
  }, [location.pathname]);

  const onMenuItemClicked = (path: string) => () =>
    !selectedKeys.includes(path) && navigate(path);
  return (
    <Layout.Header className={styles.header}>
      <Row wrap={false} justify="space-between">
        <Col flex="auto">
          <Link to="/" className={styles.logo}>
            <img src={Logo} alt="" />
          </Link>
          <Menu
            theme="dark"
            mode="horizontal"
            defaultSelectedKeys={selectedKeys}
            items={[
              {
                key: "/",
                onClick: onMenuItemClicked("/"),
                label: (
                  <Space>
                    <TeamOutlined />
                    Clients
                  </Space>
                )
              },
              {
                key: "/reservations",
                onClick: onMenuItemClicked("/reservations"),
                label: (
                  <Space>
                    <TagOutlined />
                    Reservations
                  </Space>
                )
              },
              {
                key: "/creation",
                onClick: onMenuItemClicked("/creation"),
                label: (
                  <Space>
                    <AuditOutlined />
                    Creation
                  </Space>
                )
              }
            ]}
          />
        </Col>

        <Col flex="none">
          <span onClick={onOpenDrawer} className={styles.accountAvatar}>
            <Avatar icon={<UserOutlined />} size="large" />
          </span>
        </Col>
      </Row>

      <Drawer
        title="Information sur le compte"
        closable
        visible={isDrawerVisible}
        onClose={onCloseDrawer}
        width={320}
      >
        <Card bordered={false}></Card>
        <Button
          icon={<PoweroffOutlined />}
          onClick={onLogout}
          loading={isLoading}
          danger
          className={styles.signOut}
        >
          Se déconnecter
        </Button>
      </Drawer>
    </Layout.Header>
  );
};

export default Header;
