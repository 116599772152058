import { Button, Dropdown, Menu, Space } from "antd";
import Icon, {
  AuditOutlined,
  EyeOutlined,
  PlusOutlined,
  DeleteOutlined,
  EllipsisOutlined
} from "@ant-design/icons";
import MainLayout, { MainContent } from "modules/layout/dashboard";
import { useNavigate } from "react-router-dom";
import Table from "components/table";
import { usePopup } from "utils/use-popup";
import AddCompanyModal from "./add-company";
import { useCreations } from "./queries";
import { IStatus } from "pages/applications/queries";
import { formatDate } from "utils/date-format";
import DeleteCompany from "./delete";

const Creation: React.FC = () => {
  const navigate = useNavigate();
  const addCompanyPopup = usePopup();
  const deleteCompanyPopup = usePopup<string>();

  const { data, isLoading } = useCreations();

  return (
    <MainLayout breadcrumbs={breadcrumbs}>
      <MainContent
        extra={
          <Button
            key="1"
            type="primary"
            onClick={() => addCompanyPopup.open()}
            icon={<PlusOutlined />}
          >
            Ajouter Compagnie
          </Button>
        }
      >
        <Table
          dataSource={data}
          loading={isLoading}
          rowKey="id"
          columns={[
            {
              title: "Date début",
              dataIndex: "domiciliation_date",
              key: "domiciliation_date",
              render: (cell) => formatDate(cell),
              sorter: (a, b) =>
                a.domiciliation_date?.localeCompare(b.created_at)
            },
            {
              title: "Nom compagnie",
              dataIndex: "name",
              key: "name",
              sorter: (a, b) => a.name?.localeCompare(b.company_name)
            },
            {
              title: "Nom responsable",
              dataIndex: "manager_name",
              key: "manager_name",
              sorter: (a, b) => a.manager_name?.localeCompare(b.company_name)
            },
            {
              title: "Telephone responsable",
              dataIndex: "manager_phone",
              key: "manager_phone",
              sorter: (a, b) => a.manager_phone?.localeCompare(b.company_name)
            },
            {
              title: "email responsable",
              dataIndex: "manager_email",
              key: "manager_email",
              sorter: (a, b) => a.manager_email?.localeCompare(b.company_name)
            },
            {
              title: "Status",
              dataIndex: "status",
              key: "status",
              render: (cell: IStatus) => {
                return (
                  <div style={{ textTransform: "uppercase" }}>
                    {cell === "completed" ? "Terminé" : cell}
                  </div>
                );
              },
              sorter: (a, b) => a.status?.localeCompare(b.status)
            },
            {
              key: "action",
              title: "Action",
              width: 100,
              render: (record: any) => (
                <Dropdown
                  trigger={["click"]}
                  overlay={
                    <Menu
                      style={{ width: "180px" }}
                      items={[
                        {
                          key: "view",
                          icon: <EyeOutlined />,
                          label: "Voir",
                          onClick: () => navigate(`view/${record.id}`)
                        },
                        {
                          key: "close",
                          label: "Archiver",
                          style:
                            record.status === "completed"
                              ? { color: "red" }
                              : {},
                          icon: <DeleteOutlined />,
                          disabled: record.status !== "completed",
                          onClick: () => deleteCompanyPopup.open(record.id)
                        }
                      ]}
                    />
                  }
                >
                  <Button shape="circle" icon={<EllipsisOutlined />} />
                </Dropdown>
              )
            }
          ]}
        />
        <AddCompanyModal {...addCompanyPopup} />
        <DeleteCompany {...deleteCompanyPopup} />
      </MainContent>
    </MainLayout>
  );
};

export default Creation;

const breadcrumbs = [
  {
    content: (
      <Space>
        <Icon component={() => <AuditOutlined />} />
        Creation
      </Space>
    ),
    path: "/creation"
  }
];
