import { createContext, useContext, useState } from "react";
import { useAccessToken } from "./hooks";

interface IProps {
  children: React.ReactNode;
}

interface AuthContextProps {
  accessToken: string | null;
  setAccessToken: any;
}

export const AuthContext = createContext<AuthContextProps>({
  accessToken: "",
  setAccessToken: null
});

const AuthProvider: React.FC<IProps> = ({ children }) => {
  const [accessToken, setAccessToken] = useState(useAccessToken());
  const value = { accessToken, setAccessToken };
  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export default AuthProvider;

export const useAuth = () => {
  const auth = useContext(AuthContext);
  if (auth === undefined) {
    throw new Error(`useAuth must be used within a AuthProvider`);
  }
  return auth;
};
